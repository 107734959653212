// Типы позиций
// 1 - сжатия
// 2 - растяжения
// 3 - кручения
// 4 - изделия
// 5 - зубья
// 7 - манжетные
// 8 - стопорные кольца
// 9 - торсионные
//

const POSITION_TYPE = {
  is1: (id) => id === 1,
  is2: (id) => id === 2,
  is3: (id) => id === 3,
  is4: (id) => id === 4,
  is5: (id) => id === 5,
  is7: (id) => id === 7,
  is8: (id) => id === 8,
  is9: (id) => id === 9
}

const mixinPositionType = {
  props: {
    typePosition: {
      type: Object,
      required: true
    }
  },
  computed: {
    typeId () {
      return this.typePosition?.id
    },

    // жесткость
    visibleStiffness () {
      return [1, 2].includes(this.typeId)
    },
    // ⊥
    visiblePerpendicularity () {
      return [1].includes(this.typeId)
    },
    // допуск по виткам
    visibleTurnsLimit () {
      return [1, 2, 7, 8, 9].includes(this.typeId)
    },

    visibleDInner () {
      return [5, 9].includes(this.typeId)
    },
    visibleDOutput () {
      return [1, 2, 3, 7, 8].includes(this.typeId)
    },

    notType48 () {
      return this.notType4 && !this.isType8
    },
    notType4 () {
      return !this.isType4
    },
    notType2 () {
      return !this.isType2
    },

    isType17 () {
      return this.isType1 || this.isType7
    },
    isType127 () {
        return this.isType1 || this.isType2 || this.isType7
    },
    isType359 () {
      return this.isType3 || this.isType5 || this.isType9
    },
    isType12359 () {
      return this.isType1 || this.isType2 || this.isType3 || this.isType5 || this.isType9
    },
    isType178 () {
      return this.isType1 || this.isType7 || this.isType8
    },
    isType35 () {
      return this.isType3 || this.isType5
    },
    isType1 () {
      return POSITION_TYPE.is1(this.typeId)
    },
    isType2 () {
      return POSITION_TYPE.is2(this.typeId)
    },
    isType3 () {
      return POSITION_TYPE.is3(this.typeId)
    },
    isType4 () {
      return POSITION_TYPE.is4(this.typeId)
    },
    isType5 () {
      return POSITION_TYPE.is5(this.typeId)
    },
    isType7 () {
      return POSITION_TYPE.is7(this.typeId)
    },
    isType8 () {
      return POSITION_TYPE.is8(this.typeId)
    },
    isType9 () {
      return POSITION_TYPE.is9(this.typeId)
    }
  }
}

export {
  mixinPositionType
}
